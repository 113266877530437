import "leaflet";
import "leaflet-gpx";

import "leaflet-contextmenu";
import "../lib/azure-maps-leaflet";



import "leaflet/dist/leaflet.css"
import "leaflet-contextmenu/dist/leaflet.contextmenu.css"
import "./style.css"


let osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19
})

let authOptions = {
    authType: "subscriptionKey",
    clientId: "0ddf07fa-fcc9-4b60-a1d2-a0b37abf1543",
    subscriptionKey: "85MDYdLD6Rsco9ViG9JbMooS9RR6RgQkQiri-KXulD0"
};

//Use the static function for creating Azure Maps tile layer easily.
let satellite = L.tileLayer.azureMaps({
    authOptions: authOptions,
    maxZoom: 19,
    tilesetId: 'microsoft.imagery'
})

let map = L.map('map', {
        center: [50.0593727, 6.6003289],
        zoom: 17,
        layers: [osm, satellite],
        attributionControl: false,
        contextmenu: true,
        contextmenuWidth: 140,
        contextmenuItems: [{
            text: 'Show coordinates',
            callback: showCoordinates
        }, {
            text: 'Center map here',
            callback: centerMap
        }]
    }
);

function showCoordinates (e) {
    alert(e.latlng);
}

function centerMap (e) {
    map.panTo(e.latlng);
}


let baseMaps = {
    "OpenStreetMap": osm,
    "Satellite": satellite
};

let coordinates = [
    [50.05921, 6.599433],
    [50.059909, 6.600788],
    [50.059751, 6.60086],
    [50.059534, 6.601434],
    [50.058642, 6.59986],
    [50.059014, 6.599543],
]
let area = L.polygon(coordinates, {
    color: "#002200",
    opacity: 0.8,
    fillOpacity: 0.1,
    weight: 1
});
map.addLayer(area);

var flurweg = addPopup(map, 50.061895, 6.599052, 'Flurweg 7', 'flurweg.jpg', 'https://goo.gl/maps/vqefaHa18q2gUPpQ8');

addPopup(map, 50.068988, 6.599881, 'Klooster', 'klooster.jpg', 'https://www.sanktthomas.de/');
addPopup(map, 50.059656, 6.595868, 'Vliegvissen', 'kyll.jpg', 'https://sankt-thomas-eifel.de/wp-content/uploads/2022/06/Hinweise-fuer-Angelsportler.pdf');
addPopup(map, 50.0616, 6.599361, 'Hottub', 'hottub.jpg');
addPopup(map, 50.059198, 6.599919, 'Vuurtje', 'vuur.gif');
addPopup(map, 50.041572, 6.593074, 'Zur Post', 'zur-post.jpg', 'https://www.hotelzurpostkyllburg.de/');
addPopup(map, 49.8651428, 6.4018744, 'Buggy Brothers', 'buggy.jpg', 'https://www.buggy-brothers.de/');
addPopup(map, 50.061643, 6.599197, 'BBQ', 'bbq.jpg');




function createGPXLayer(gpx, color, enabled = false, weight = 2) {
    let layer = new L.GPX(gpx, {
        async: true,
        marker_options: {
            startIconUrl: null,
            endIconUrl: null,
            shadowUrl: null
        },
        polyline_options: 
            {
                color: color,
                opacity: 0.5,
                weight: weight,
                lineCap: 'round'
            }
    }).on('loaded', function(e) {
      map.fitBounds(e.target.getBounds());
    });
    if (enabled) {
        layer.addTo(map);
    }
    return layer;
}

var route_10k = createGPXLayer('/gpx/10k.gpx', 'blue'); 
var route_6k = createGPXLayer('/gpx/6k.gpx', 'blue'); 
var buggy_tour = createGPXLayer('/gpx/buggy_tour.gpx', 'orange', true, 4); 
var to_kyllburg = createGPXLayer('/gpx/to_kyllburg.gpx', 'yellow'); 

let overlayLayers = {
    "Buggy Tour": buggy_tour,
    "Naar Zur Post": to_kyllburg,
    "Route 10K" : route_10k,
    "Route 6K" : route_6k,
    "Haasberg ": area
}

var layerControl = L.control.layers(baseMaps, overlayLayers).addTo(map);
L.control.scale().addTo(map);

map.setView(flurweg.getLatLng(), 17);


function addPopup(map, lat, lon, title, image = null, url = null) {
    let marker = new L.Marker([lat,lon]);
    marker.bindPopup(popupHTML(title, image, url));
    marker.addTo(map);
    return marker;
}

function popupHTML(title, image, url) {
    return '<div class="popupTable" ' + imageHTML(image, title) + '>' + title + iconHTML(title, url) + '</div>';
}

function imageHTML(image, title) {
    if (image != null) {
        return 'style="background-image: url(\'img/' + image + '\')" ';
    } else {
        return '';
    }
}


function iconHTML(title, url) {
    if (url != null ) { 
        return '<a href="' + url + '" target="' + title + '"><img height="16" src="img/info.svg"></a>';
    } else {
        return '';
    }
}

